<template>
  <div>
    <div class="home row" v-if="xuanzhiHou">
      <div class="home-body col-xs-12 col-sm-6 col-md-4" v-for="(item,index) in homeList" :key="index">
        <q-card class="home-option" flat v-if="$store.state.paperLanguage == 'zh'">
          <div class="exam-trial-flag" v-if="item.authority ==0">{{$t('home.Trial')}}</div>
          <q-card-section>
            <div class="card-title">{{item.exam_name_cn}}</div>
            <div class="card-des">
              <p>{{item.exam_knowledge_cn}}</p>
            </div>
          </q-card-section>
          <q-separator style="background: #999999"  />
          <q-card-actions>
            <div class="card-btn">
              <p>
                <span>{{item.exam_total_cn}}{{$t('home.Questions')}} / </span>
                <span>{{item.exam_time_cn}}{{$t('home.Minutes')}} / </span>
                <span>{{item.exam_grade_cn}}{{$t('home.Points')}}</span>
              </p>
              <q-btn class="card-btn-star" @click="starExam('/exam',item,'exam')" flat>START</q-btn>
            </div>
          </q-card-actions>
        </q-card>
        <q-card class="home-option" flat v-if="$store.state.paperLanguage == 'en'">
          <div class="exam-trial-flag" v-if="item.authority ==0">{{$t('home.Trial')}}</div>
          <q-card-section>
            <div class="card-title">{{item.exam_name_en}}</div>
            <div class="card-des">
              <p>{{item.exam_knowledge_en}}</p>
            </div>
          </q-card-section>
          <q-separator style="background: #999999"  />
          <q-card-actions>
            <div class="card-btn">
              <p>
                <span>{{item.exam_total_en}}{{$t('home.Questions')}} / </span>
                <span>{{item.exam_time_en}}{{$t('home.Minutes')}} / </span>
                <span>{{item.exam_grade_en}}{{$t('home.Points')}}</span>
              </p>
              <q-btn class="card-btn-star" @click="starExam('/exam',item,'exam')" flat>START</q-btn>
            </div>
          </q-card-actions>
        </q-card>
      </div>
    </div>
    <div class="" v-else>
      <q-dialog v-model="tanchuang" content-class="pop" persistent transition-show="scale" transition-hide="scale">
        <div class="pop-box">
<!--          <q-btn class="pop-close" @click="tanchuang = false" style="color: #FF0000" icon="close" flat round dense  />-->
          <div class="pop-title">{{$t('home.PleaseSelectYourLevel')}}</div>
          <q-form ref="myForm" @submit="onSubmit">
            <q-select v-model="identity" bottom-slots :options="options" >
              <template v-slot:prepend>
                <span class="input-lable">{{$t('home.Grade')}}*</span>
              </template>
              <template v-slot:hint>
                <span class="input-hint">{{$t('home.PleaseSelectYourLevel')}}</span>
              </template>
            </q-select>
            <div class="pop-bottom">
              <q-btn class="pop-btn" unelevated  flat :label="$t('home.determine')" type="submit" />
            </div>
          </q-form>
        </div>
      </q-dialog>
    </div>
  </div>

</template>

<script>
export default {
  name: "ChooseExam",
  data(){
    return{
      homeList:[],
      xuanzhiHou:false,
      tanchuang:true,
      identity:null,
      options:[],
    }
  },
  created() {

    this.getBuyLeveList()
    // this.getExamLeve()
  },
  methods:{
    // 获取已购买等级
    getBuyLeveList(){
      this.$http.post('user_product/check_product_user',).then(res =>{
        res.data.forEach(item=>{
          this.options.push({
            label: item.name,
            value:item.product_type_id
          })
        })

      }).catch(err =>{
        console.log(err)
      })
    },
    onSubmit(){
      this.$refs.myForm.validate().then(success => {
        if (success){
          this.xuanzhiHou = true
          this.getChooseExamList()
        }else {
          console.log('11111')
        }
      })

    },
    // 获取试卷列表 exam/index
    getChooseExamList(){
      this.$http.post('exam/index',{
        product_type_id:this.identity.value
      }).then(res=>{
        console.log(res)
        this.homeList = res.data.list
      })
    },
    // 去答题
    starExam(url,item,type){
        this.$router.push({path:url,query:{id:item.id,type:type}})
        localStorage.setItem('exam_time',item.exam_time_cn)
    },
    // 判断是否购买试卷 user_product/check_product_user

  }
}
</script>

<style scoped lang="less">
  .home {
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 15px;
    margin-top: 20px;
  }
  .home-body {
    margin-bottom: 20px;
  }
  .home-option {
    background: #F4F4F4;
    /*border: 1px solid #d3d3d3;*/
    border-radius: 20px;
    margin-right: 15px;
    position: relative;
    overflow: hidden;
    .exam-trial-flag{
      position: absolute;
      width: 80px;
      font-size: 12px;
      top: -26px;
      color: #fff;
      padding-top: 40px;
      text-align: center;
      padding-bottom: 3px;
      background: #FF0000;
      box-shadow: 0 0 10px #c5c5c5;
      transform: rotate(45deg);
      right: -36px;
    }
  }
  .card-title{
    font-size: 24px;
    color: #333333;
  }
  .card-des p{
    color: #666666;
    font-size: 14px;
    line-height: 23px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 0;
    margin-top: 18px;
  }
  .card-btn{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 23px;
    padding-right: 21px;
     p{
       font-size: 14px;
       color: #999999;
       margin: 0;
     }
    .card-btn-star{
      width: 70px;
      height: 37px;
      background: #ED711C;
      color: #ffffff;
      font-size: 14px;
      border-radius: 19px;
    }
  }
  .q-field__inner{
    background: #FFFFFF;
    border-radius: 13px;
    padding: 0 30px;
  }
  .input-lable{
    font-size: 20px;
    color: #333333;
  }
  .q-field__messages{
    font-size: 16px;
    color: #666666;
    padding-left: 30px;
  }
  .q-field--with-bottom{
    padding-bottom: 46px;
  }
</style>